import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас |До Самого Моря: свіжий підхід до оренди
			</title>
			<meta name={"description"} content={"Водіння незабутніх подорожей, по одній оренді одночасно"} />
			<meta property={"og:title"} content={"Про Нас |До Самого Моря: свіжий підхід до оренди"} />
			<meta property={"og:description"} content={"Водіння незабутніх подорожей, по одній оренді одночасно"} />
			<meta property={"og:image"} content={"https://blackseabeaches.live/img/people_3.3.jpg"} />
			<link rel={"shortcut icon"} href={"https://blackseabeaches.live/img/3077421.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blackseabeaches.live/img/3077421.png"} />
			<meta name={"msapplication-TileColor"} content={"https://blackseabeaches.live/img/3077421.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--primary"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про{" "}
					<br />
					До Самого Моря
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Заняття глибше в етос і цінності, що живлять приводу.Зрозумійте нашу прихильність пропонувати безпрецедентний досвід оренди автомобілів і чому кожен привід з нами відчуває себе чітко особливим.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Почати
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://blackseabeaches.live/img/people_2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Наша місія
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				До Самого Моря вийшов із простої ідеї: оренда автомобілів може бути більше, ніж просто транзакцій - вони можуть бути досвідом.Ми розуміємо суть хорошої подорожі, визнаючи, що це не лише про місце призначення, а про саму їзду.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
					as="h2"
				>
					Що робить нас різними
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Поки ми підтримуємо збалансований підхід, стійкість для нас є життєво важливою.Наші машини проходять регулярні перевірки, щоб вони працювали ефективно, мінімізуючи свій екологічний слід.
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 22px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						+
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Підхід, орієнтований на клієнтів
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							В основі До Самого Моря лежить наша непохитна прихильність до наших клієнтів.Кожне рішення, кожна особливість та кожен автомобіль у нашому флоті обираються з вами на увазі.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					margin="0px 0px 22px 0px"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						+
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Свіжа перспектива
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми постійно впроваджуємо інновації, шукаємо способи зробити ваш досвід оренди більш плавним та незабутнім.Від наших кураційних маршрутів до наших комплектів комфорту ми додаємо дотики, які мають значення.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						+
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Якість над кількістю
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							У нас може не бути найбільшого флоту, але ми похвалимось одними з найкращих транспортних засобів у цій галузі.Наша увага зосереджена на якості, гарантуючи, що кожен автомобіль пропонує преміум -відчуття та безпечний привід.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://blackseabeaches.live/img/people_4.1.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Приєднуйтесь до нас у дорозі
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Вибір До Самого Моря означає вибрати більш вишуканий, персоналізований досвід оренди.Давайте зробимо ваші подорожі незабутніми разом.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});